import React from 'react'
import { useNavigate } from 'react-router-dom';
import ImagePopup from  './ImagePopup';
import { ProgramDescription } from '../text/programDescription';

function MainPage1() {
  const navigate = useNavigate();

  function tryTheApp() {
    console.log('Try the app');
    navigate('/page2')
  }

    
  const newText = ProgramDescription.split('\n').map((str,ix) => <p key={ix} className='pb-2'>{str}</p>);

  return (
    <div>
        <div className='flex justify-center text-center font-bold text-2xl w-full px-5 pt-6'>
            <span>
                Do you have trouble remembering when (OR IF?) you took your medications?
                <br />
                This can be particularly troublesome if you take your medications on an as-needed basis.
                <br />
                If you don't remember when, or if, you could end up taking too many, or take them too often.
            </span>
        </div>
        <div className='w-full mx-4'>
            <hr className="h-1 my-4 bg-gray-500 border-0" />
            <div className='flex flex-col items-center justify-center'>
                <div className='text-lg m-2 ml-4 w-8/12'>
                    We have found that most "medication apps" primarily remind you when to take your medications,
                    but they are difficult to use when all you want to do is record when you actually took them.
                </div>
                <div className='flex mt-4 items-center justify-between'>
                    <ImagePopup source='/assets/MedsScreen.png' />
                    <ImagePopup source='/assets/HistoryScreen.png' />
                    {/* <img className='h-[285px] w-[160px] mx-2 flex flex-col border-black border-2' alt='Med Screen' src='/assets/MedsScreen.png' /> */}
                    {/* <img className='h-[285px] w-[160px] mx-2 flex flex-col border-black border-2' alt='History Screen' src='/assets/HistoryScreen.png'/> */}
                    <div className='ml-4'>
                        <button
                            className='subBtn'
                            onClick={() => tryTheApp()}
                        >
                            Try<br />the<br />app
                        </button>
                    </div>
                </div>
            </div>
            <hr className="h-1 my-4 bg-gray-500 border-0" />
        </div>
        <div className='h-full justify-center items-center'>
            <div className='mx-20 text-2xl'>{newText}</div>
        </div>
    </div>
  )
}

export default MainPage1