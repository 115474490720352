import { getHistFromFB, getMedsFromFB } from "../common/apiHelpers";
import { getUserDataState, setHist, setMeds, setUserDataStateLoaded } from "../store/storeValtio";


export async function loadUserData(userId) {
    let response = await getMedsFromFB(userId);
    let result = await response;
    setMeds(result);

    response = await getHistFromFB(userId);
    result = await response;
    setHist(result);

    setUserDataStateLoaded(true);
    return(getUserDataState())
}