import React from 'react'

function MainPage2() {
  return (
    <div>
        <div className='flex justify-center text-center font-bold text-2xl w-full px-5 pt-6'>
            Get the ITookItWhen App
        </div>
        <div className='flex justify-center text-center font-semibold text-xl w-full px-5 pt-6'>
            <span>
                The ITookItWhen app is a Progressive Web Application (PWA)
                <br />
                A PWA is a type of webpage or website known as a web application. A such, it is able to run as a normal website, 
                but it is also able to be installed on a mobile device or desktop as if it were a stand-alone application. 
                ITookItWhen (ITIW) is intended to be installed so that it can run whether or not there is an internet connection. 
                All data used by the app is stored on the local device so it is not dependent on any external system in order to function.
                <br />
            </span>
        </div>
    </div>
  )
}

export default MainPage2