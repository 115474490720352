import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserId, getUserDataStateLoaded } from '../store/storeValtio';
import { loadUserData } from '../userPages/loadUserData';

export default function UserMenu() {
    const navigate = useNavigate();
    const currentUserId = getCurrentUserId();
    console.log('UserMenu', currentUserId);

    useEffect(() => {
        if (!getCurrentUserId()) navigate('/');
    })

    useEffect(() => {
        async function loadData(id) {
          let response = await loadUserData(id);
          let result = await response;
          console.log('loadData', result)
        }
        if (!getUserDataStateLoaded()) {
          const regId = getCurrentUserId();
          loadData(regId);
          }
      })
    
    

    function clicked(target) {
        console.log('clicked', target);
        navigate(target);
    }

    return (
      <div className='flex flex-row justify-center'>
        <div className='mt-8 text-2xl font-semibold' >
            <MenuItem title={'Medications'} target={'/meds'} doClick={(t)=>clicked(t)} />
            <MenuItem title={'History'} target={'/history'} doClick={(t)=>clicked(t)} />
            <MenuItem title={'Schedule'} target={'/schedule'} doClick={(t)=>clicked(t)} />
        </div>
      </div>
    );
  }

  function MenuItem({title,target,doClick}) {

    function clicked() {
      console.log('MI clicked', target);
      doClick(target);
    }

    return (
        <div 
        onClick={clicked}
        className={'text-lg small:text-xl py-1 small:py-2 font-semibold hover:font-bold hover:cursor-pointer '} 
        >
            {title}
        </div>
    );

  }