import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import { login } from '../../firestore/firestore';
import { parseForm } from '../common/generalHelpers';
import { login } from '../common/apiHelpers';
import { getCurrentUserId, updateUserState } from '../store/storeValtio';

export default function LoginForm() {
  const [ loginError, setLoginError ] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log('LoginForm');

  const handleSubmit = e => {
    e.preventDefault()
    const formItems = e.target;
    console.log('formItems', formItems);
    var formVals = parseForm(formItems);
    const email = formVals.email;
    const password = formVals.password;
    doLogin(email, password);
  }

  async function doLogin(email, password) {
    setLoading(true);
    console.log('doLogin', email, password);
    var user = null;
    try {
      user = await login(email,password);
      console.log('user', user);
    } catch(error) {
      console.log('doLogin catch', error);
      setLoginError(error);
      setLoading(false);
      return;
    }
    console.log('doLogin',user);
    if (user && user.length > 0) {
      console.log('doLogin success',user[0]);
      const newState = {
        loaded: true,
        authenticated: true,
        currentUser: user[0],
        currentUserId: user[0].id
      }
      console.log('newState', newState);
      updateUserState(newState);
      console.log('newState id', getCurrentUserId());
      setLoading(false);
      navigate('/usermenu');
    } else {
      console.log('doLogin fail');
      setLoginError('Login failed!');
      setLoading(false);
    }
  }

  return (
      <div className='h-full w-full bg-gray-200'>
        <div className='flex flex-col justify-center items-center px-6 tablet:px-8'>
          <div className='mt-10 w-full max-w-md'>
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
              Sign In to your account
            </h2>
            <p className='mt-2 text-center text-sm text-gray-600 max-w'>
              Need to register?
              <button onClick={()=>navigate('/register')} className='ml-2 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500'>
                Register
              </button>
            </p>
          </div>

          {loginError && (
            <div
              className='bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700'
              role='alert'
            >
              {loginError}
            </div>
          )}

          <div className='mt-4 mx-auto w-full max-w-md'>
            <div className='bg-white py-8 px-6 shadow rounded-lg '>
              <form className='mb-0 space-y-6' onSubmit={handleSubmit}>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-700' > Email address </label> 
                  <div className='mt-1'>
                    <input id='email' name='email' type='email' autoComplete='email' required className='formText' /> 
                  </div>
                </div>
                <div>
                  <label htmlFor='password' className='block text-sm font-medium text-gray-700' > Password </label> 
                  <div className='mt-1'>
                    <input id='password' name='password' type='password' autoComplete='current-password' required className='formText' /> 
                  </div>
                </div>
                <div>
                  <button
                    type='submit' 
                    disabled={loading} 
                    className='subBtn'> 
                      Sign In 
                    </button> 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
}
