import React, { useEffect } from 'react';
import { getCurrentUserId } from '../store/storeValtio';
import { useNavigate } from 'react-router-dom';

export default function Registered() {
    const navigate = useNavigate();

    const currentUserId = getCurrentUserId();
    console.log('Registered', currentUserId);

    useEffect(() => {
        if (!currentUserId) {
            console.log("No Current User");
            navigate('/login');
        }
    });

    return (
        <div>
            <div>
                Registered
            </div>
        </div>
    )
}