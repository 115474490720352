import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import history from './store/history';

const ProtectedRoute = ({ children }) => {
  const authenticated = true;
  const navigate = useNavigate();
  
  //console.log("ProtectedRoute: ", authenticated, children);
  useEffect(() => {
    if (!authenticated) {navigate('/');}
    }, [authenticated,navigate]);

  //if (!authenticated) {navigate('/');}
  return children;
};

export default ProtectedRoute;
