import React, {useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import MainScreen from '../screens/MainScreen';
import ProtectedRoute from '../ProtectedRoute';
import Registered from '../registered/Registered';
import LoginForm from '../registered/LoginForm';
import UserMenu from './UserMenu';
import MedicationList from '../userPages/medications/MedicationList';
import BottomNav from './BottomNav';
import Footer from './Footer';
import HistoryList from '../userPages/history/HistoryList';
import Schedule from '../userPages/schedule/Schedule';


export default function Dashboard() {
  console.log('Dashboard', window.location.href);
  const [refresh, setRefresh] = useState({value: false});

  return (
    <div id='dashboard'>
        <div className='main'>
          <Routes>
            <Route path="/" element={<ProtectedRoute><MainScreen /></ProtectedRoute>}/>
            <Route path="/page1" element={<ProtectedRoute><MainScreen page={1} /></ProtectedRoute>}/>
            <Route path="/page2" element={<ProtectedRoute><MainScreen page={2} /></ProtectedRoute>}/>
            <Route path="/usermenu" element={<ProtectedRoute><UserMenu /><BottomNav /></ProtectedRoute>}/>
            <Route path="/meds" element={<ProtectedRoute><MedicationList /><BottomNav /></ProtectedRoute>}/>
            <Route path="/hist" element={<ProtectedRoute><HistoryList /><BottomNav /></ProtectedRoute>}/>
            <Route path="/schedule" element={<ProtectedRoute><Schedule /><BottomNav /></ProtectedRoute>}/>
            <Route path="/registered" element={<ProtectedRoute><Registered /></ProtectedRoute>}/>
            <Route path="/login" element={<ProtectedRoute><LoginForm /></ProtectedRoute>}/>
            {/* 
            */}
            <Route path="*" element={<ProtectedRoute><MainScreen /></ProtectedRoute>} />  
          </Routes>
        </div>
        
    </div>
);
}
