import moment from 'moment';
import { getJD, timeToMoment, timeToMinutes, dayOfWeek } from './generalHelpers';
import { getCurrentUserAwakeTime, getCurrentUserBedTime, getMeds, getHist } from '../store/storeValtio';

export function sortMedications(meds)
{
  meds.sort((a, b) => {
    if (a.isPaused !== b.isPaused)
    {
      if (a.isPaused) return 1;
      return -1;
    }
    if (!a.isPaused) {
      // if paused, ignore sort order
      var order = a.sortOrder - b.sortOrder;
      if (order !== 0) return order;
    }
    order = a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' });
    return order;
  });

  return meds;
}

export function formatTaken(hist) {
  let lt = formatLastTaken(hist);
  if (lt.startsWith('Last t')) lt = 'T' + lt.substring(6);
  return lt;
}

export function formatLastTaken(hist) {
  if (!hist) return 'Never taken';
  const skipped = (hist.status !== "taken") ? true : false;
  //console.log('formatLastTaken', skipped, hist.status);

  let hm = '';
  if (!skipped) {
    hm = 'Last taken ';
    if (hist.quantity !== 1) {
      hm = hist.quantity.toString() + ' taken ';
    }
  } else {
    hm = 'Dose skipped ';
  }

  const ltAgo = hm + moment(hist.date).fromNow();
  return (ltAgo);
}

const perDay = [
  'Never',
  'Once a day',
  'Twice a day',
  'Three times a day',
  'Four times a day',
  'Five times a day',
  'Six times a day'
];
export function formatOftenX(occurance, often) {
  let times = 'Whenever';
  if (often === 'daily') {
      times = perDay[occurance];
      return (times);
    }
  }

export function formatDosage(med,user) {
  //console.log('fd',med);
  let dosage = '';
  if (!med) return dosage;
  switch (med.form) {
    case 'powder':
      dosage += med.dosage + ' ' + med.measurement;
      break;
    default:
      dosage += med.dosage + ' ' + med.form;
      break;
  }

  //dosage += med.dosage + ' ' + med.measurement;
  dosage += formatOften(med);
  if (med.whileAwake) {
    dosage += formatStarting(med,user);
    dosage += formatEnding(med,user);
  }
  //console.log('fd', med.name, dosage);
  return dosage;
}

export function formatOften(med) {
  //console.log('formatOften',med);
  let often = '';
  if (!med) return often;
  if (med.often === 'hourly') {
    let n = parseFloat(med.occurance);
    often += ' every ';
    often += n + ' hour' ;
    if (n > 1) often += 's';
  } else if (med.often === 'daily') {
    often += ' ' + med.occurance + ' times a day';
  } else if (med.often === 'everyndays') {
    often += ' every ' + med.occurance + ' days';
  } else if (med.often === 'weekly') {
    often += ' ' + med.occurance + ' times a week';
  } else if (med.often === 'asNeeded') {
    often += ' as needed ';
  }
  return often;
}

export function formatStarting(med,user) {
  if (!med.often) return '';
  if (!user) return '';
  let starting = ' starting at ';
  starting += med.times ? med.times : user.awakeTime;
  return starting;
}

export function formatEnding(med,user) {
  if (!med.often) return '';
  let starting = ' ending at ';
  starting += med.times ? med.times : user.bedTime;
  return starting;
}

export function nextOccuranceX(med,user) {

  const now = moment().startOf('m');
  const awakeToday = med.whileAwake
    ? timeToMoment(user.awakeTime)
    : moment().startOf('day');
  const awakeTomorrow = moment(awakeToday).add(1, 'day'); // next time to take
  const bedToday = med.whileAwake
    ? timeToMoment(user.bedTime)
    : moment().add(1, 'day').startOf('day');
  //console.log(med.name, awakeToday, bedToday, now);

  if (med.often === 'hour') {
    let n = parseFloat(med.occurance) * 60; // every n minutes starting at start time
    // find first time greater than current time
    let ntt = awakeToday; // next time to take
    //console.log('at', awakeTomorrow);
    while (true) {
      ntt.add(n, 'm');
      //console.log('ntt', ntt);
      if (ntt > bedToday || ntt.day() > bedToday.day()) {
        // after bedtime, get tomorrows date
        ntt = awakeTomorrow;
        //console.log('ntt-t', ntt);
        break; // by definition this will be > now
      } else if (ntt >= now) break;
    }
    //console.log('mtt', ntt);
    return ntt.toDate();
  }

  if (med.often === 'daily') {
    if (med.occurance === 1) {
      // should check for set time or awake or bed time
      return awakeToday.toDate();
    }
    let awake = moment.duration(bedToday.diff(awakeToday)).asMinutes();
    let n = parseFloat(awake) / (med.occurance - 1.0); // every n minutes starting at start time
    let ntt = awakeToday; // next time to take
    //console.log('awake times', med.name, awake, med.occurance, n, ntt);
    // find first time greater than current time
    while (ntt < now) {
      ntt.add(n, 'm');
      //console.log('ntt', ntt, bedToday);
      if (ntt > bedToday || ntt.day() > bedToday.day()) {
        // after bedtime, get tomorrows date
        ntt = awakeTomorrow;
        //console.log('ntt-t', ntt);
        break; // by definition this will be > now
      }
    }
    //console.log('mtt', ntt);
    return ntt.toDate();
  }
}

export function lookupMed(medId) {
  const meds = getMeds();
  //console.log('lookupMeds', meds, medId, typeof(medId));
  let mc = null;
  if (!meds) return mc;
  for ( var i = 0; i < meds.length; ++i) {
    const m = meds[i];
    //console.log('lookupMedId', m);
    if (m.medicationId === medId) {
      mc = meds[i];
      return mc;
    }
  }
  return mc;
};

export function medicationOverdue(hist) {
  if (hist.taken) return false;
  const now = moment().toDate();
  if (hist.due > now) return false;
  return true;
}

export function getLatestHistory(mId) {
  const history = getHist();
  //console.log('glt', history, mId);
  let latest = new Date(2000, 1, 1, 0, 0);
  let latestHist = null;
  if (!history) return latest;
  for ( var i = 0; i < history.length; ++i) {
    let h = history[i];
    //console.log('glt h', h);
    if (mId === h.medicationId) {
      //console.log('glt hist', latest, h.date);
      if (h.date > latest) {
        latestHist = h;
        latest = h.date;
      }
    }
  };
  //console.log('glt latest',latestHist);
  return latestHist;
}

export function minutesFromDose(lh) {
  if (!lh) return null;
  const diff = moment().diff(lh.date, 'minute');
  return diff;
}

export function isToday(date) {
  var isToday = moment(date).isSame(new Date(), "day");
  return isToday;
}

// how many taken today
export function takenTodayCount(mId) {
  const history = getHist();
  var today = 0;
  const todayJD =  getJD(moment())
  //console.log(todayJD);
  history.forEach((h,ix) => {
    if (h.medicationId === mId ) {
      const taken = moment(h.date);
      const takenJD = getJD(taken);
      const days = todayJD - takenJD;
      //console.log(days);
      if (days === 0) ++today;
      }
  });
  return today;
}

export function getHoursUntilNext(due) {
  const d = moment(due, 'hh:mm A');
  const diff = d.diff(moment(), 'minute')/60;
  //console.log('untilNext', due, d, diff);
  return diff;
}

export function nextDueDateTime(med) {
  let dueDateTime = new Date();
  if (med.often === 'asNeeded') return null;

  const lh = getLatestHistory(med.medicationId);
  const tToday = takenTodayCount(med.medicationId);

  //console.log('ndd', tToday, lh);

  if (med.often === 'hourly') {
    // if hourly, next due depends on last taken
    // no history or none today, due now
    if (lh === null || tToday === 0) {
      // if none today, use wake time as due date
      dueDateTime = getAwakeTime();
      //console.log('awake:', dueDateTime);
      return dueDateTime;
    }
    // othewise x hours from last taken
    dueDateTime = moment(lh.date).add(med.occurance, 'hour').toDate(); 
    return dueDateTime;
  } else if (med.often === 'daily') {
    // if daily, next due depends on time of day
    if (tToday === 0) {
      // none taken today, due time is first dose
      return timeToMoment(med.schedule[0]);
    }
    // if not all taken today, time is next dose time
    if (tToday < med.occurance) {
      const ms = med.schedule[tToday]
      //console.log('today', tToday, ms);
      return timeToMoment(ms);
    }
    // all taken today, next due is tomorrow
    //console.log('next due: ', med.schedule);
    var  ttmoment = null;
    //if (typeof med.schedule !== 'undefined' && med.schedule.length > 0) {
    if (med.schedule.length > 0) {
      const ms = med.schedule[0];
      ttmoment = timeToMoment(ms).add(1, 'd');
    }
    //console.log('tomorrow', tToday, ttmoment, ms);
    return ttmoment;
} else if (med.often === 'everyndays') {
    // if daily, next due depends on time of day
    const takeAt = timeToMoment(med.schedule[0]);
    if (lh === null) {
      // none taken, due time is first dose
      return takeAt;
    }

    var min = timeToMinutes(med.schedule[0]); // minutes from midnight
    min += 1440 * med.occurance; // add days in hours

    let next = moment(lh.date).startOf('day').add(min, 'm');
    return next;
  } else if (med.often === 'weekly') {
    if (med.schedule[0].startsWith('NotScheduled')) {
      const takeTime = med.schedule[0].substring(13);
      if (lh === null) {
        const time = timeToMoment(takeTime);
        //console.log('weeklyNS', takeTime, time);
        return time;
      }
      // next is n days from last taken
      const min = (7 / med.occurance) * 1440;
      const time = moment(lh.date).add(min, 'm')
      //console.log('weeklyNS', takeTime, min, lh.date, time);
      return time;
    } else {
      // scheduled days, collect them
      const now = moment();
      const nDays = med.schedule.length;
      const today = now.day();
      const todayMfm = (now.hour() * 60) + now.minutes();
      //console.log('weekly', today, todayMfm);
      var sched = new Array(nDays).fill(now);
      for (var i = 0; i < nDays; ++i) {
        const ms = med.schedule[i].split(';');
        const wd = dayOfWeek(ms[0]);
        const time = ms[1];
        var no = nextOccurance(wd, time);
        sched[i] = no;
        //console.log('weekly', i, wd, time, no);

      }
      if (nDays > 1) {
        sched.sort((a,b) => a - b);
        //console.log('sched', sched);
        if (isToday(sched[0])) {
          // if taken today, return next scheduled
          if (tToday > 0) return sched[1];
        }
        return sched[0];
      } else {
        // once a week
        //console.log('sched', sched);
        var due = sched[0];
        if (isToday(due)) {
          // if none taken today, return today
          if (tToday === 0) return due;
          // otherwise add one week
          return due.add(1,'week');
        }


        return due;
      }
    }

  }

  return moment();
}

export function nextDueList() {
  let nextDue = [];
  const meds = getMeds();
  if (!meds) return nextDue;
  for ( var i = 0; i < meds.length; ++i) {
    const m = meds[i];
    const lt = getLatestHistory(m.medicationId);
    const nd = nextDueDateTime(m);
      nextDue.push(
        {
        medicationId: m.medicationId,
        name: m.name,
        lastTaken: lt ? lt.date : null,
        skipped: lt ? lt.status === 'skipped' : false,
        nextDue: nd,
        snoozeUntil: null,
        isPaused: m.isPaused
        });
    };
  nextDue.sort(function (a, b) {
    if (a.nextDue === b.nextDue) return 0;
    if (a.isPaused) return 1;
    if (b.isPaused) return -1;
    if (a.nextDue === null) return 1;
    if (b.nextDue === null) return -1;
    return a.nextDue - b.nextDue
  });
  //console.log("nextDue: ", nextDue);
  return (nextDue);
}

// get moment of next occurance of day(0-7) and time (HH:MM xM)
export function nextOccurance(day, time) {
  // get start of today
  let date = moment().startOf('day');
  let tfm = timeToMinutes(time); // minutes from midnight

  for (var i=0; i<7; ++i) {
    if (date.day() === day) {
      // same day of week, set time
      date.add(tfm, 'm');
      //console.log('nextOccurance', day, time, date);
      return date;
    }
    date.add(1,'day');
  }

  return null;
}

export function getAwakeTime() {
  let hours = 8;
  let minutes = 0;
  const awake = getCurrentUserAwakeTime();
  if (awake) {
    const time = awake.match( /(\d+)(?::(\d\d))?\s*(P?)/ );
    console.log('awake time:', awake, time);
    hours = parseInt( time[1] + (time[3] ? 12 : 0));
    minutes = parseInt(time[2]) || 0;
  }
  let awakeTime = moment().startOf('day');
  awakeTime.set('hour', hours);
  awakeTime.set('minute', minutes);
  return awakeTime;
}

export function getBedTime() {
  let hours = 8;
  let minutes = 0;
  const bed = getCurrentUserBedTime();
  if (bed) {
    const time = bed.match( /(\d+)(?::(\d\d))?\s*(P?)/ );
    console.log('bed time:', time);
    hours = parseInt( time[1]) + (time[3] ? 12 : 0);
    minutes = parseInt( time[2]) || 0;
  }
  const bedTime = moment().startOf('day');
  bedTime.set('hour', hours);
  bedTime.set('minute', minutes);
  return bedTime;
}

