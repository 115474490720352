import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userState } from '../../store/storeValtio';
import { nextDueList } from '../../common/medicationHelpers.js';
import { formatTimeShort } from '../../common/generalHelpers';
import moment from 'moment';

export default function Schedule() {
  console.log('Schedule');
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const currentUserId = userState.currentUserId;
  const now = new Date();

  const ndl = nextDueList();
  //console.log('NextDue', ndl);

  function goTo(target) {
    console.log('goTo: ', target);
    navigate(target);
  }

  
  const schedBtn = 'w-4/12 min-w-fit py-2 px-4 border-2 rounded-md shadow-sm text-sm font-medium text-white';

  return (
    <div className='mcard'>
      {/* <div className='mcard-screen flex justify-between'>
        <div>Schedule</div>
        <div className='font-normal text-lg'>{currentUserName}</div></div> */}
      {ndl.length === 0 &&
      <div className='w-full flex flex-col justify-center'>
        <div className='text-center my-8'>
          You do not have any medications scheduled at this time
        </div>
      </div>
      }
      {/* <div className='text-lg font-medium text-green-600 bg-gray-200 border rounded mb-2 p-2'>{dt}</div> */}
      <div id='scroll_area' className='lcard'>
        {ndl.map((d,ix) => {
            //console.log('ndl', ix, d);
            const od = d.nextDue ? (d.nextDue <= now) : false;
            const nextDue = d.nextDue ? moment(d.nextDue).format("dd MM/DD hh:mm A") : 'No Due Date';
            const when = d.nextDue ? moment(d.nextDue).fromNow() : "";
            const due = d.isPaused ? 'Paused' : 'Due: ' + when;
            const lastTaken = d.lastTaken ? `Last taken: \n ${formatTimeShort(d.lastTaken)}` : 'Never taken';
            const lts = formatTimeShort(d.lastTaken);
            return (
              <div className='mcard border-2 border-gray-200 mb-1' key={ix}>
                <div className='mcard-header flex'>
                  <span className={'text-green-500 text-2xl'}>{d.name}</span>
                </div>
                <div className='mrow'>
                  <div className='w-8/12'>
                    <div className={`new-line m-1 p-1 font-bold text-base small:text-lg phone:text-xl tablet:text-2xl rounded-md ${!d.isPaused && od ? 'bg-red-400' : 'bg-gray-200'}`}>
                      {due} on
                      <br/>
                      &nbsp;&nbsp;&nbsp;{nextDue}
                      <br />
                      {(d.lastTaken && !d.skipped) && (<div>Last taken on<br/>&nbsp;&nbsp;&nbsp;{lts}</div>)}
                      {(d.lastTaken && d.skipped) && (<div>Dose skipped on<br/>&nbsp;&nbsp;&nbsp;{lts}</div>)}
                      {!d.lastTaken && (<div>Never taken</div>)}
                    </div>
                  </div>
                  <div className='mcol inline-flex w-full justify-end'>
                    {!d.isPaused && (
                      <button onClick={()=>goTo(`/take/${d.medicationId}/schedule`)} className='mbtn w-full m-1 text-3xl text-white bg-green-600'>Take</button>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
