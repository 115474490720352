import React from 'react';
import NavBar from './navigation/NavBar';
//import Footer from './navigation/Footer';
import Dashboard from './navigation/Dashboard';

function App() {
  return (
    <div className="flex flex-col w-full h-full">
      <NavBar />
      <Dashboard />
    </div>
  );
}

export default App;
