import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCurrentUserId, resetUserState, setCurrentUserId } from '../store/storeValtio';

export default function NavBar() {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  let currentUserId = getCurrentUserId();
  console.log('NavBar', currentUserId);

  function goRegister() {
    if (currentUserId)
    {
      console.log('logoff');
      resetUserState();
      currentUserId = null;
      setCurrentUserId(null);
      navigate('/');
    } else {
      console.log('goRegister');
      navigate('/registered');
    }
    setRefresh(!refresh);
  }
  
  function goMain() {
    navigate('/');
  }
  
  return (
    <div id='navbar' className='flex flex-row justify-between w-full bg-slate-700 py-4'>
        <div 
          className='flex items-center px-4 hover:cursor-pointer' 
          onClick={()=>goMain()}
          >
          <img className='w-14 h-14 ml-2 mr-8' src='/logo.png' alt='logo' />
          <span className='text-white font-bold text-3xl'>I Took It When?</span>
        </div>
        <div className="flex itms-center pr-4">
          <button
            className='p-2 border-2 rounded-md bg-slate-500 text-white font-semibold text-xl float-right'
            onClick={()=>goRegister()}
            >
            {currentUserId ? 'Logoff' : 'Registered User Login'}
          </button>
        </div>
    </div>
  );
}

