import React, { useState } from 'react';
import * as mh from '../../common/medicationHelpers';
import * as gh from '../../common/generalHelpers';
import { getHist, getCurrentUserId  } from '../../store/storeValtio';

export default function HistoryList({medId}) {
  const currentUserId = getCurrentUserId();
  var medsHistory = getHist();
  console.log('MedicationHistoryList', medId);

  // if history for just one med, filter it out
  var hist = null;
  if ( medId ) {
    hist = medsHistory.filter(h => h.medicationId === medId);
  } else {
    hist = medsHistory;
  }


  // This will show the Cofirmation Box


  if (hist.length === 0) {
    var msg = "???";
    if (!medId) {
      msg = 'There is no medication history';
    } else {
      msg = 'There is no history for this medication';
    }
    return(
      <div className='w-full flex flex-col justify-center font-semibold text-xl text-center my-8'>
          {msg}
      </div>
  )}

  return (
    <div>
      {hist.map((h, ix) => {
        console.log("hist list map:", h);
        //console.log("hist list date:", h.date);
        // if for single med, skip the others

        const med = mh.lookupMed(h.medicationId);
        if (!med) {
          // should never happen
          console.log("Med NOT FOUND: ", h.medicationId);
          return(<div>Med Not Found: {h.medicationId} (h.historyId)</div>);
        }
        //console.log('lookupMed',med, h.medicationId);
        //const t = moment(h.date).format('hh:mm A');
        const t = gh.formatTime(h.date);
        const ltAgo = mh.formatTaken(h);
        const td = `Taken ${ltAgo} at ${t}`;

        return (
          <div className='mcard border-2 border-gray-200 mb-1' key={ix}>
            {typeof medId === 'undefined' &&
            <div className='mcard-header'>{med.name}</div>
            }
            <div className='histItem'>
              <div>
                {ltAgo}
                <br />
                {t}
              </div>
            </div>
          </div>
        );
      })}
      {/* <div style={{ height: '80px' }} /> */}
    </div>
  );
}