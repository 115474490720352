import React from 'react';
import LoadingComponent from '../../common/LoadingComponent';
import MedicationListItem from './MedicationListItem';
import { getLatestHistory } from '../../common/medicationHelpers.js'
import { useNavigate } from 'react-router-dom';
import { getCurrentUserId, getMeds } from '../../store/storeValtio';

export default function MedicationList() {
  const navigate = useNavigate();
  const userId = getCurrentUserId();
  const meds = getMeds();
  console.log('MedicationList', meds, userId);

  if (!meds) return (<LoadingComponent />);

  //const medsSort = sortMedications(meds);
  const medsSort = meds;
  return (
    <div className='mcard'>
      {meds.length === 0 &&
      <div className='w-full flex flex-col justify-center'>
        <div className='text-center my-8'>
          You do not have any medications entered
          <br/>
          Use the link below to add a medication:
        </div>
        <div className='w-full flex justify-center'>
        <button 
          className='bg-blue-200 w-4/12 p-4 rounded-md'
          onClick={()=>navigate('/create')}
        >Add medication</button>
        </div>
      </div>
      }
      {/* <div className='text-lg font-medium text-green-600 bg-gray-200 border rounded mb-2 p-2'>{dt}</div> */}
      <div id='scroll_area' className='lcard'>
        {medsSort.map((m,ix) => {
          const latest = getLatestHistory(m.medicationId);
          //console.log('ML latest', m, m.medicationId, latest);
          return (<MedicationListItem med={m} latestHist={latest} key={ix} />);
        })}
      </div>
    </div>
  );
}
